import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import LoadingScreen from '../common/LoadingScreen';

interface RouterProviderProps {
  children: React.ReactNode;
}

export const RouterProvider = ({ children }: RouterProviderProps) => {
  return (
    <BrowserRouter>
      <Suspense fallback={<LoadingScreen />}>
        {children}
      </Suspense>
    </BrowserRouter>
  );
}; 