import React, { createContext, useContext, useState, useEffect } from 'react';
import { authService } from '../services/auth.service';
import { AuthState, AuthContextType } from '../types/auth';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthState>(() => {
    const accessToken = localStorage.getItem('authToken');
    const refreshToken = localStorage.getItem('refreshToken');
    return {
      isAuthenticated: !!accessToken,
      user: null,
      accessToken,
      refreshToken
    };
  });

  useEffect(() => {
    const verifyToken = async () => {
      const token = localStorage.getItem('authToken');
      if (token) {
        try {
          // Add an endpoint to verify token validity
          // const response = await authService.verifyToken();
          // Update user info if needed
        } catch (error) {
          // Token is invalid
          logout();
        }
      }
    };

    verifyToken();
  }, []);

  const login = async (username: string, password: string) => {
    const response = await authService.login({ username, password });
    setAuthState({
      isAuthenticated: true,
      user: response.user,
      accessToken: response.access_token,
      refreshToken: response.refresh_token,
    });
    localStorage.setItem('authToken', response.access_token);
    localStorage.setItem('refreshToken', response.refresh_token);
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
    setAuthState({
      isAuthenticated: false,
      user: null,
      accessToken: null,
      refreshToken: null,
    });
    window.location.href = '/login';
  };

  const refreshAccessToken = async () => {
    const response = await authService.refreshToken({ refresh_token: authState.refreshToken! });
    setAuthState((prevState) => ({
      ...prevState,
      accessToken: response.access_token,
    }));
    localStorage.setItem('authToken', response.access_token);
  };

  return (
    <AuthContext.Provider value={{ authState, login, logout, refreshAccessToken }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 