import { createTheme, ThemeOptions } from '@mui/material';

export const getThemeOptions = (mode: 'light' | 'dark'): ThemeOptions => ({
  palette: {
    mode,
    primary: {
      main: '#2563eb',
      light: '#60a5fa',
      dark: '#1e40af',
    },
    secondary: {
      main: '#7c3aed',
      light: '#a78bfa',
      dark: '#5b21b6',
    },
    background: {
      default: mode === 'dark' ? '#121212' : '#f8fafc',
      paper: mode === 'dark' ? '#1e1e1e' : '#ffffff',
    },
    grey: {
      100: mode === 'dark' ? '#2d2d2d' : '#f1f5f9',
      200: mode === 'dark' ? '#424242' : '#e2e8f0',
      300: mode === 'dark' ? '#616161' : '#cbd5e1',
    }
  },
  typography: {
    fontFamily: '"Plus Jakarta Sans", "Roboto", "Helvetica", "Arial", sans-serif',
    h6: {
      fontWeight: 600,
      letterSpacing: '-0.015em',
    },
    body1: {
      letterSpacing: '-0.015em',
    }
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 500,
        },
      },
    },
  },
});

export const createAppTheme = (mode: 'light' | 'dark') => {
  return createTheme(getThemeOptions(mode));
}; 