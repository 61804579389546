import { useLocation, useNavigate } from 'react-router-dom';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Box,
  Typography,
  ListItemButton,
} from '@mui/material';
import { DRAWER_WIDTH, MENU_ITEMS } from '../../config/constants';

interface SidebarProps {
  open: boolean;
}

const Sidebar = ({ open }: SidebarProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? DRAWER_WIDTH : 0,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
          backgroundColor: 'background.paper',
          transform: open ? 'none' : 'translateX(-100%)',
          transition: 'transform 0.2s',
        },
      }}
    >
      <Box 
        sx={{ 
          p: 2,
          borderBottom: '1px solid',
          borderColor: (theme) => theme.palette.mode === 'dark' ? 'grey.800' : 'grey.200',
          display: 'flex',
          alignItems: 'center',
          height: '64px',
        }}
      >
        <Typography 
          variant="h6" 
          sx={{ 
            color: 'primary.main',
            fontWeight: 600,
          }}
        >
          Workflow Admin
        </Typography>
      </Box>
      <List sx={{ p: 2 }}>
        {MENU_ITEMS.map(({ text, icon: Icon, path }) => (
          <ListItem key={text} disablePadding sx={{ mb: 1 }}>
            <ListItemButton
              selected={location.pathname === path}
              onClick={() => navigate(path)}
              sx={{
                borderRadius: 1,
                '&.Mui-selected': {
                  backgroundColor: 'primary.light',
                  '&:hover': {
                    backgroundColor: 'primary.light',
                  },
                },
                '&:hover': {
                  backgroundColor: (theme) => 
                    theme.palette.mode === 'dark' ? 'grey.800' : 'grey.100',
                },
              }}
            >
              <ListItemIcon 
                sx={{ 
                  color: location.pathname === path ? 'primary.main' : 'text.secondary',
                  minWidth: 40 
                }}
              >
                <Icon />
              </ListItemIcon>
              <ListItemText 
                primary={text} 
                primaryTypographyProps={{
                  fontSize: '0.875rem',
                  fontWeight: location.pathname === path ? 600 : 500,
                  color: location.pathname === path ? 'primary.main' : 'text.primary',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
