import {
  Dashboard,
  Task,
  Chat,
  AccountTree,
} from '@mui/icons-material';

export const DRAWER_WIDTH = 240;

export const MENU_ITEMS = [
  { text: 'Dashboard', icon: Dashboard, path: '/' },
  { text: 'Prompts', icon: Chat, path: '/prompts' },
  { text: 'Tasks', icon: Task, path: '/tasks' },
  { text: 'Workflows', icon: AccountTree, path: '/workflows' },
] as const; 