import { ThemeProvider as MuiThemeProvider, CssBaseline } from '@mui/material';
import { useRoutes } from 'react-router-dom';
import Layout from './components/layout/Layout';
import { ThemeProvider } from './contexts/ThemeContext';
import { AuthProvider } from './contexts/AuthContext';
import { useTheme } from './contexts/ThemeContext';
import { createAppTheme } from './theme';
import { routes } from './config/routes';
import { RouterProvider } from './components/providers/RouterProvider';

const ThemedApp = () => {
  const { mode, setMode, actualMode } = useTheme();
  const theme = createAppTheme(actualMode);
  const element = useRoutes(routes);

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <AuthProvider>
        <Layout mode={mode} setMode={setMode}>
          {element}
        </Layout>
      </AuthProvider>
    </MuiThemeProvider>
  );
};

function App() {
  return (
    <ThemeProvider>
      <RouterProvider>
        <ThemedApp />
      </RouterProvider>
    </ThemeProvider>
  );
}

export default App;
